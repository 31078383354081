<template>
  <ul class="filter-shortcut-links">
    <li v-for="(shortcut, i) in shortcuts.list" :key="i" class="filter-shortcut-links__items">
      <a
        :href="shortcut.url"
        :target="shortcut.target"
        :nofollow="shortcut.target === '_blank'"
        :noindex="shortcut.target === '_blank'"
        @click="pushTagData(shortcut)"
      >
        {{ shortcut.label }}
      </a>
    </li>
    <div class="filter-shortcut-links__divider"></div>
  </ul>
</template>

<script>
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler/AnalyticsHandler';

export default {
  name: 'FilterShortcutLinks',
  props: {
    shortcuts: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    pushTagData(shortcut) {
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        ecommerce: 'undefined',
        category: '__pageCategory__',
        action: 'shortcut link',
        label: `${shortcut.label}::${shortcut.url}`.toLowerCase(),
        event_name: 'shortcut_link_filter',
        filter_value: `${shortcut.label}::${shortcut.url}`.toLowerCase(),
        event_detail: '__pageCategory__',
      });
    },
  },
};
</script>
