<template>
  <div v-if="showLiveChat" class="oap-live-chat">
    <LoadScript :src="scriptUrl" @loaded="initLiveChat" />
    <button
      ref="liveChatCta"
      v-tag:useractionevent="taggingEvent"
      class="oap-live-chat__cta on-center"
      style="display: none"
      @click.prevent="openChat"
    >
      <slot />
    </button>
  </div>
</template>

<script>
import LoadScript from '../../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';
import { tag } from '@Foundation/analyticsHandler/directives/tag';

export default {
  name: 'LiveChat',
  components: {
    LoadScript,
  },
  directives: {
    tag,
  },
  props: {
    id: { type: String, required: true },
    scriptUrl: { type: String, required: true },
    chatUrl: { type: String, required: true },
    initIdFirstParam: { type: String, required: true },
    initIdSecondParam: { type: String, required: true },
    cookiecategory: { type: String, required: false, default: 'default' },
  },
  data() {
    return {
      location: window.location.href,
      showLiveChat: false,
    };
  },
  computed: {
    taggingEvent() {
      return {
        category: 'live chat',
        label: this.location,
        action: 'select::live chat',
        ecommerce: 'undefined',
      };
    },
  },
  created() {
    window.addEventListener('load', () => {
      this.showLiveChat = true;
    });
  },
  methods: {
    /*  eslint-disable */
    initLiveChat() {
      liveagent.init(this.chatUrl, this.initIdFirstParam, this.initIdSecondParam);
      liveagent.setChatWindowWidth(900);
      liveagent.setChatWindowHeight(675);

      if (!window._laq) {
        window._laq = []
      }

      /* istanbul ignore next */
      window._laq.push(() => {
        liveagent.showWhenOnline(this.id, this.$refs.liveChatCta);
      });
    },
    openChat() {
      liveagent.startChat(this.id);
    },
    /*  eslint-enable */
  },
};
</script>
