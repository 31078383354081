<template>
  <div class="filterTags__tagsGroup">
    <fieldset class="filterTags__tagsGroupWrapper">
      <legend class="filterTags__tagsGroupTitle">{{ filter.title }}</legend>
      <ul id="filterTags__tagsTagWrapper" class="filterTags__tagsTagWrapper" data-tag-wrapper>
        <li v-for="facet in filter.facets" :key="facet.id" class="filterTags__tagsTagItem">
          <input
            :id="facet.id"
            :key="facet.id"
            class="isSrOnly"
            type="checkbox"
            :value="facet.title"
            :name="facet.title"
            :checked="facet.selected"
            :disabled="facet.disabled"
            @change="
              filterProducts(facet.urlToApiWithParams, facet.urlToShow);
              likecountert();
              prepareTagging($event, filter, facet);
            "
          />
          <label
            class="filterTags__tag"
            :class="{ '-selected': facet.selected, '-disabled': facet.disabled }"
            :for="facet.id"
          >
            {{ facet.title }}
            <span v-if="!facet.disabled" class="filterTags__facetCount">({{ facet.count }})</span>
            <a
              :href="facet.urlToShow"
              class="isSrOnly"
              :tabindex="facet.disabled ? -1 : 1"
              :aria-hidden="facet.disabled"
            >
              {{ facet.title }}
            </a>
          </label>
        </li>
      </ul>
    </fieldset>
  </div>
</template>

<script>
import { tag } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';

let TAGGING_FILTERS = [];

export default {
  name: 'FilterTags',
  directives: {
    tag,
  },
  props: {
    filter: { type: Object, required: true },
  },
  data() {
    return {
      allitemsArticle: [],
      filterValue: '',
      category: window.wsf.AnalyticsVariables.pageCategory
        ? window.wsf.AnalyticsVariables.pageCategory
        : 'content page',
    };
  },
  /* istanbul ignore next */
  methods: {
    filterProducts(apiUrl, pushState = false) {
      this.$parent.filterProducts(apiUrl, pushState);
    },
    likecountert() {
      setTimeout(() => {
        var activeArticles = document.querySelectorAll('.active_like');
        for (var i = 0; i < activeArticles.length; i++) {
          var allarticleIdsActive = activeArticles[i].getAttribute('id');
          var activeId = document.getElementById(allarticleIdsActive);
          activeId.classList.remove('active_like');
        }

        var allAricles = document.querySelectorAll('.like__btn');
        for (let i = 0; i < allAricles.length; i++) {
          var allarticleIdsAll = allAricles[i].getAttribute('id');
          this.allitemsArticle.push(allarticleIdsAll);
        }

        var articlelike = JSON.parse(localStorage.getItem('articlelike'));
        var numberOfChildren = articlelike && articlelike.length;
        if (numberOfChildren > 0) {
          for (let i = 0; i < articlelike.length; i++) {
            for (var j = 0; j < allAricles.length; j++) {
              var allarticleIds = allAricles[j].getAttribute('id');
              var currentItem = articlelike[i];
              if (allarticleIds == currentItem) {
                document.getElementById(articlelike[i]).classList.add('active_like');
              }
            }
          }
        }

        const selectedFilterTags = document.querySelectorAll('.-selected');
        const filterValues = [];
        selectedFilterTags.forEach((item) => filterValues.push(item.control.name));
        this.filterValue = filterValues.join('::');

        fetch(`/api/sitecore/article/GetLikeCountList?&idList=${this.allitemsArticle}`)
          .then((response) => response.json())
          .then((jsonData) => {
            var hideableSections = document.querySelectorAll('.like__btn');
            for (var i = 0; i < hideableSections.length; i++) {
              var allarticleIds = hideableSections[i].getAttribute('id');
              if (jsonData.list[i].likeCount <= 0) {
                document
                  .getElementById(allarticleIds)
                  .getElementsByClassName('like__number')[0].innerHTML = '';
              } else {
                document
                  .getElementById(allarticleIds)
                  .getElementsByClassName('like__number')[0].innerHTML = jsonData.list[i].likeCount;
              }
            }
          })
          .catch((e) => console.error(e));
      }, 2599);
    },
    prepareTagging(event, filter, facet) {
      const filterAlreadyExists = TAGGING_FILTERS.filter(
        (filter) => filter.value === facet.title.toLowerCase()
      ).length;

      if (filterAlreadyExists) {
        // remove it from the list
        TAGGING_FILTERS.splice(
          TAGGING_FILTERS.findIndex(({ value }) => value === facet.title.toLowerCase()),
          1
        );
      } else {
        if (event.target.checked) {
          TAGGING_FILTERS.push({
            category: filter.title.toLowerCase(),
            value: facet.title.toLowerCase(),
          });
        }
      }

      const GROUPED_TAGGING_FILTERS = TAGGING_FILTERS.reduce((group, { category, value }) => {
        group[category] = group[category] ?? [];
        group[category].push(value);
        return group;
      }, []);

      const labelFromTaggingFilters = Object.keys(GROUPED_TAGGING_FILTERS).map((key) => {
        return `${key}::${GROUPED_TAGGING_FILTERS[key].join('::')}`;
      });

      this.$emit('on-tag-change', {
        tagging: {
          category: this.category,
          action: 'filter::by needs',
          label: `${filter.title}::${facet.title}`,
          filter_type: 'filter',
          filter_value: labelFromTaggingFilters.join('::'),
          event_name: 'use_filters',
        },
      });
    },
  },
};
</script>
