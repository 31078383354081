<template>
  <div
    v-if="selectedDataset.showClearFilters"
    id="selectedFilters__wrapper"
    class="selectedFilters__wrapper"
  >
    <ul v-if="selectedColors.length > 0" class="selectedFilters__group -colors">
      <li v-for="color in selectedColors" :key="color.id" class="selectedFilters__item">
        <button
          class="selectedFilters__button"
          :aria-label="'Remove: ' + color.title"
          @click="
            filterProducts(color.urlToApiWithParams, color.urlToShow);
            likecountert();
          "
        >
          <span
            class="selectedFilters__itemColor"
            :style="{ backgroundColor: color.extraParams.hexColor }"
          >
            {{ color.title }}
          </span>
          <svg class="selectedFilters__closeIcon" :aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
          </svg>
        </button>
      </li>
    </ul>
    <div
      v-if="selectedTags.length > 0 || selectedBrands.length > 0"
      class="selectedFilters__group -tags"
    >
      <div class="selectedFilters__groupWrapper">
        <Slider :activate="false" :fade-copy="false" :scale-factor="1">
          <ul>
            <li v-for="(brand, index) in selectedBrands" :key="index" class="selectedFilters__item">
              <button
                class="selectedFilters__button"
                :aria-label="'Remove: ' + brand.title"
                @click="
                  filterProducts(brand.urlToApiWithParams, brand.urlToShow);
                  likecountert();
                "
              >
                <span class="selectedFilters__itemText">
                  {{ brand.title }}
                  <svg class="selectedFilters__closeIcon" :aria-hidden="true">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
                  </svg>
                </span>
              </button>
            </li>
            <li v-for="tag in selectedTags" :key="tag.id" class="selectedFilters__item">
              <button
                class="selectedFilters__button"
                :aria-label="'Remove: ' + tag.title"
                @click="
                  filterProducts(tag.urlToApiWithParams, tag.urlToShow);
                  likecountert();
                "
              >
                <span class="selectedFilters__itemText">
                  {{ tag.title }}
                  <svg class="selectedFilters__closeIcon" :aria-hidden="true">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
                  </svg>
                </span>
              </button>
            </li>
          </ul>
        </Slider>
      </div>
    </div>
    <button
      class="selectedFilters__clearAll"
      @click="
        clearAllHandler();
        likecountert();
      "
    >
      <span class="selectedFilters__clearAllText">
        {{ selectedDataset.dictionary.clearFacets }}
        <svg class="selectedFilters__closeIcon" :aria-hidden="true">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg>
      </span>
    </button>
  </div>
</template>

<script>
import Slider from '../../../../Slider/code/Scripts/components/slider.vue';
import eventBus from '@loreal/eventbus-js';
import { mapGetters } from 'vuex';
import { HERO_VIDEO_UPDATE } from '../constants/eventNames';

export default {
  name: 'SelectedFilters',
  components: {
    Slider,
  },
  props: {
    selectedDataset: { type: Object, required: true },
  },
  data() {
    return {
      allitemsArticle: [],
    };
  },
  computed: {
    ...mapGetters('filterList', ['selectedTags', 'selectedColors', 'selectedBrands']),
  },
  methods: {
    filterProducts(apiUrl, pushState = false) {
      this.$parent.setInitialItems({ clearAllFilter: true });
      this.$parent.getData(apiUrl, pushState).then(() => eventBus.emit(HERO_VIDEO_UPDATE));
      eventBus.emit('updateScroller');
    },
    clearAllHandler() {
      const toggleFacetsButton = document.getElementById('toggle-facets');
      setTimeout(() => {
        toggleFacetsButton.focus();
      }, 100);

      this.filterProducts(
        this.selectedDataset.clearFilterApiUrl,
        this.selectedDataset.clearFilterUrl
      );
    },
    likecountert() {
      setTimeout(() => {
        var activeArticles = document.querySelectorAll('.active_like');
        for (var i = 0; i < activeArticles.length; i++) {
          var allarticleIdsActive = activeArticles[i].getAttribute('id');
          var activeId = document.getElementById(allarticleIdsActive);
          activeId.classList.remove('active_like');
        }

        var allAricles = document.querySelectorAll('.like__btn');
        for (let i = 0; i < allAricles.length; i++) {
          var allarticleIdsAll = allAricles[i].getAttribute('id');
          this.allitemsArticle.push(allarticleIdsAll);
        }

        var articlelike = JSON.parse(localStorage.getItem('articlelike'));
        var numberOfChildren = articlelike && articlelike.length;
        if (numberOfChildren > 0) {
          for (let i = 0; i < articlelike.length; i++) {
            for (var j = 0; j < allAricles.length; j++) {
              var allarticleIds = allAricles[j].getAttribute('id');
              var currentItem = articlelike[i];
              if (allarticleIds == currentItem) {
                document.getElementById(articlelike[i]).classList.add('active_like');
              }
            }
          }
        }

        fetch(`/api/sitecore/article/GetLikeCountList?&idList=${this.allitemsArticle}`)
          .then((response) => response.json())
          .then((jsonData) => {
            var hideableSections = document.querySelectorAll('.like__btn');
            for (var i = 0; i < hideableSections.length; i++) {
              var allarticleIds = hideableSections[i].getAttribute('id');
              if (jsonData.list[i].likeCount <= 0) {
                document
                  .getElementById(allarticleIds)
                  .getElementsByClassName('like__number')[0].innerHTML = '';
              } else {
                document
                  .getElementById(allarticleIds)
                  .getElementsByClassName('like__number')[0].innerHTML = jsonData.list[i].likeCount;
              }
            }
          })
          .catch((e) => console.error(e));
      }, 2599);
    },
  },
};
</script>
