<template>
  <div v-if="gridDataset.showLoadMore" class="loadMore">
    <OapLoader v-if="loading" />
    <button
      class="loadMore__button button -secondaryAnimated"
      :class="{ disabled: loading }"
      @click.prevent="loadMore()"
      @keydown.enter.prevent="loadMoreKeyboard()"
      @keydown.space.prevent="loadMoreKeyboard()"
    >
      <span v-if="gridDataset.dictionary && gridDataset.dictionary.moreResults">{{
        gridDataset.dictionary.moreResults
      }}</span>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import eventBus from '@loreal/eventbus-js';
import OapLoader from '../../../../OapLoader/code/Scripts/components/OapLoader.vue';

export default {
  name: 'Loadmore',

  components: {
    OapLoader,
  },

  props: {
    gridDataset: { type: Object, required: true },
  },

  computed: mapState('filterList', {
    loading: (state) => state.loading,
  }),

  methods: {
    loadMore() {
      this.filterProducts(this.gridDataset.loadMoreApiUrl, this.gridDataset.loadMoreUrl);
    },
    loadMoreKeyboard() {
      this.filterProducts(this.gridDataset.loadMoreApiUrl, this.gridDataset.loadMoreUrl);
      eventBus.emit('focus::gridElement', this.gridDataset.list.length);
    },
    filterProducts(apiUrl, pushState = false) {
      this.$parent.setLoadMore();
      this.$parent.getData(apiUrl, pushState);
    },
  },
};
</script>
