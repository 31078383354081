<template>
  <div class="sorting__wrapper">
    <select
      :id="sortingLabel"
      v-model="sortingOption"
      class="sorting__select"
      :aria-label="sortingLabel"
      @change="getSortingData($event)"
    >
      <option
        v-for="(option, index) in sortingOptions"
        :key="index"
        :value="option.label"
        :selected="option.selected"
        :disabled="option.selected === sortingLabel ? true : false"
        :sort-api-url="option.urlToApiWithParams"
        :url-to-show="option.urlToShow"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { AnalyticsHandler } from '@Foundation';

export default {
  name: 'FilterSorting',

  props: {
    gridType: { type: String, required: true },
    sortingLabel: { type: String, required: true },
    sortingOptions: { type: Array, required: true },
  },

  computed: {
    selectedOption() {
      return this.sortingOptions.filter((option) => option.selected)[0];
    },
    sortingOption: {
      get() {
        return this.selectedOption ? this.selectedOption.label : this.sortingLabel;
      },
      set() {},
    },
  },

  watch: {
    sortingOptions() {
      this.insertDefaultLabel();
    },
  },

  mounted() {
    this.insertDefaultLabel();
  },

  methods: {
    ...mapActions('filterList', ['getNewItems']),

    sort(apiUrl, pushState = false) {
      this.getNewItems(apiUrl);
      this.pushUrlToBrowser(pushState || apiUrl);
    },

    pushUrlToBrowser(url) {
      history.pushState({}, document.title, url);
    },

    getValueForSort(element, state = '', attribute) {
      return this.$el.querySelector(`${element}${state}`).getAttribute(attribute);
    },

    getSortingData(event) {
      this.$parent.setInitialItems({ clearAllFilter: true });
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        category: this.gridType === 'ArticlesList' ? 'content page' : 'product selector page',
        action: `sorting::${this.gridType.replace('List', '')}`,
        label: this.sortingOptions[event.target.options.selectedIndex].label,
      });
      this.sort(
        this.getValueForSort('option', ':checked', 'sort-api-url'),
        this.getValueForSort('option', ':checked', 'url-to-show')
      );
    },

    insertDefaultLabel() {
      if (!this.selectedOption) {
        this.sortingOptions.unshift({
          label: this.sortingLabel,
          urlToApiWithParams: null,
          urlToShow: null,
          selected: true,
        });
      }
    },
  },
};
</script>
