import isEqual from 'lodash.isequal';
import transform from 'lodash.transform';
import isObject from 'lodash.isobject';
import 'whatwg-fetch';
import { MqHandler } from '../../../../Foundation/Core/code/Scripts/mqHandler';
import { AnalyticsHandler } from '@Foundation';

export const INITIAL_ITEMS = 'INITIAL_ITEMS';
const TOGGLE_FACETS = 'TOGGLE_FACETS';
export const RECIEVE_ITEMS_SUCCESS = 'RECIEVE_ITEMS_SUCCESS';
export const RECIEVE_ITEMS_FAILURE = 'RECIEVE_ITEMS_FAILURE';
export const RECEIVE_ITEMS_REQUEST = 'RECEIVE_ITEMS_REQUEST';
const colorSelectorString = 'Color Selector';
export const LIKE_BUTTON = 'LIKE_BUTTON';
const CLEAR_FILTERS = 'CLEAR_FILTERS';

const DE_COUNTRYCODE = 'DE_COUNTRYCODE';

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

export const parseJSON = (response) => response.json();

export default {
  namespaced: true,
  state: {
    filterDataset: {},
    lastFilterDataList: {},
    loading: true,
    facetsOpened: false,
    lazyImages: true,
    likeHidden:
      document.getElementById('articlelikesetting') !== null &&
      document.getElementById('articlelikesetting').value === 'False',
    deCountrycode: false,
    filterCount: 0,
    clickLoadMore: false,
  },

  getters: {
    colorCollection: (state) =>
      state.filterDataset?.filtersData?.filters.filter(
        (item) => item.type === colorSelectorString
      )[0],
    dropdownCollection: (state) =>
      state.filterDataset?.filtersData?.filters.filter((item) => item.type === 'Dropdown'),
    selectedColors: (state) => {
      const colorFacets = state.filterDataset?.filtersData?.filters.filter(
        (item) => item.type === colorSelectorString
      )[0];
      let selectedColors;

      if (colorFacets) {
        selectedColors = colorFacets.facets.filter((facet) => facet.selected === true);
      }

      return selectedColors || false;
    },
    selectedBrands: (state) => {
      const brandsFacets = state.filterDataset?.filtersData?.filters.filter(
        (item) => item.type === 'Dropdown'
      )[0];
      let selectedBrands;

      if (brandsFacets) {
        selectedBrands = brandsFacets.facets.filter((facet) => facet.selected === true);
      }

      return selectedBrands || false;
    },
    selectedTags: (state) => {
      const selectedTags = [];

      state.filterDataset?.filtersData?.filters
        .filter((item) => item.type === 'Checklist')
        .forEach((tagsGroup) => {
          selectedTags.push(tagsGroup.facets.filter((tag) => tag.selected === true));
        });

      return [].concat.apply([], selectedTags); // eslint-disable-line
    },
    tagsCollection: (state) =>
      state.filterDataset?.filtersData?.filters.filter((item) => item.type === 'Checklist'),
    isColorWheel: (state) =>
      !!state.filterDataset?.filtersData?.filters.find(
        (facet) => facet.type === colorSelectorString
      ),
    getFilterDataset: (state) => state.filterDataset,
  },

  actions: {
    setInitialItems({ commit, getters }, items) {
      if (items.clearAllFilter) {
        commit(CLEAR_FILTERS);
        return false;
      }
      if (!items.list.length) {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'nonInteractiveEvent',
          category: 'product selector page',
          action: 'product results',
          label: 'blank page error',
        });
      }
      commit(INITIAL_ITEMS, { items, getters });
    },
    toggleFacets({ commit, state }) {
      const bodyNode = document.querySelector('body');
      const smallScreen =
        MqHandler.getMqHandler().getMqForSize('small').selected ||
        MqHandler.getMqHandler().getMqForSize('medium').selected;
      const isOverflowHiddenClass = 'is-overflow-hidden';
      if (smallScreen && !state.facetsOpened) {
        bodyNode.classList.add(isOverflowHiddenClass);
      } else if (bodyNode.classList.contains(isOverflowHiddenClass)) {
        bodyNode.classList.remove(isOverflowHiddenClass);
      }
      commit(TOGGLE_FACETS);
      commit(DE_COUNTRYCODE);
    },

    getNewItems({ commit, getters }, apiUrl) {
      commit(RECEIVE_ITEMS_REQUEST);
      return fetch(apiUrl, {
        credentials: 'include',
        cache: 'no-store',
      })
        .then(checkStatus)
        .then(parseJSON)
        .then((items) => {
          commit(RECIEVE_ITEMS_SUCCESS, { items, getters });
          commit(LIKE_BUTTON);
        })
        .catch(() => {
          commit(RECIEVE_ITEMS_FAILURE);
        });
    },
    setLoadMore({ state }) {
      state.clickLoadMore = true;
    },
  },

  mutations: {
    [RECEIVE_ITEMS_REQUEST]: (state) => {
      state.lazyImages = false;
      state.loading = true;
    },

    [TOGGLE_FACETS]: (state) => {
      state.facetsOpened = !state.facetsOpened;
    },

    [DE_COUNTRYCODE]: (state) => {
      state.deCountrycode =
        document.querySelector('html').attributes.lang !== undefined &&
        document.querySelector('html').attributes.lang.value === 'de-DE';
    },

    [INITIAL_ITEMS]: (state, { items, getters }) => {
      state.filterDataset = items;
      state.lastFilterDataList = items.list;
      state.lazyImages = true;
      state.loading = false;
      if (
        (getters.selectedBrands && getters.selectedBrands.length) ||
        (getters.selectedColors && getters.selectedColors.length) ||
        (getters.selectedTags && getters.selectedTags.length)
      ) {
        state.filterCount = 1;
      }
    },
    [RECIEVE_ITEMS_SUCCESS]: (state, { items, getters }) => {
      if (state.filterDataset.banner) {
        delete state.filterDataset.banner;
      }

      function changes(object, base) {
        return transform(object, (result, value, key) => {
          if (!isEqual(value, base[key])) {
            result[key] =
              isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
          }
        });
      }

      function difference(object, base) {
        return changes(object, base);
      }

      if (items.resetItems) {
        state.filterDataset = items;
      } else {
        const newItemsKeys = Object.keys(difference(items, state.filterDataset));

        newItemsKeys.forEach((prop) => {
          if (prop === 'items') {
            state.filterDataset.items = state.filterDataset.items.concat(items.items);
          } else {
            state.filterDataset[prop] = items[prop];
          }
        });
      }
      if (
        !getters.selectedBrands.length &&
        !getters.selectedTags.length &&
        !getters.selectedColors.length
      ) {
        let actualList = state.filterDataset.list;
        let OldList = state.lastFilterDataList;
        if (OldList != undefined) {
          state.filterDataset.list = state.lastFilterDataList = OldList.concat(actualList);
          state.filterCount = 0;
        }
      } else {
        if (!state.filterCount) {
          state.filterCount = 1;
          state.lastFilterDataList = state.filterDataset.list;
        } else {
          if (!state.lastFilterDataList.length) {
            state.lastFilterDataList = state.filterDataset.list;
          }
          if (state.clickLoadMore) {
            let actualList = state.filterDataset.list;
            let OldList = state.lastFilterDataList;
            state.filterDataset.list = state.lastFilterDataList = OldList.concat(actualList);
          }
        }
      }
      state.loading = state.clickLoadMore = false;
    },

    [RECIEVE_ITEMS_FAILURE]: (state) => {
      state.loading = false;
    },
    [LIKE_BUTTON]: (state) => {
      state.likeHidden =
        document.getElementById('articlelikesetting') !== null &&
        document.getElementById('articlelikesetting').value === 'False';
    },
    [CLEAR_FILTERS]: (state) => {
      state.lastFilterDataList = [];
    },
  },
};
