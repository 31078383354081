<template>
  <div class="filterHeader" :class="isRedesign ? '--redesign' : ''">
    <div class="videoWrapper">
      <OapVideo
        v-bind="video"
        :sources="sources"
        :placeholder-background="proxy.background"
        :placeholder-class="['filterHeader__background']"
        :controls-position="videoControlsPosition"
      ></OapVideo>
      <h1 v-if="isRedesign" class="filterHeader__heading heading1">{{ proxy.title }}</h1>
    </div>
    <article itemscope itemtype="http://schema.org/Article" class="filterHeader__information">
      <div class="filterHeader__content filterHeader__column" data-tag-wrapper>
        <h1 v-if="!isRedesign" class="filterHeader__heading heading1">{{ proxy.title }}</h1>
        <slot></slot>
        <!-- eslint-disable vue/no-v-html -->

        <div
          v-if="!isRedesign"
          id="filterDescription"
          class="filterHeader__description"
          v-html="getTruncatedText(proxy.description)"
        ></div>
        <div v-if="isRedesign" class="filterHeader__descriptionWrapper">
          <div
            id="filterDescription"
            ref="filterDescription"
            class="filterHeader__description"
            :class="{ '--expanded': expanded }"
            v-html="proxy.description"
          ></div>
          <button v-if="buttonEnabled" class="filterHeader__readButton" @click="showText()">
            {{ expanded ? readlessText : readmoreText }}
          </button>
        </div>
        <span
          v-if="
            (isReadmoreEnabled == 'true' || isReadmoreEnabled == 'true') &&
            !expanded &&
            proxy.description.length > charLimit &&
            !isRedesign
          "
        >
          ...
        </span>
        <button
          v-if="isReadmoreEnabled == 'true' || isReadmoreEnabled == 'true'"
          id="textBtn"
          @click="showText()"
        >
          {{ expanded ? readlessText : readmoreText }}
        </button>
      </div>
    </article>
  </div>
</template>

<script>
import OapVideo from '../../../../../Foundation/Core/code/Scripts/OapVideo/OapVideo';

import eventBus from '@loreal/eventbus-js';
import { mapGetters } from 'vuex';

import { HERO_VIDEO_UPDATE } from '../constants/eventNames';

export default {
  name: 'FilterHeaderContainer',
  components: {
    OapVideo,
  },
  props: {
    video: {
      type: Object,
      default: () => {},
    },
    background: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    isReadmoreEnabled: {
      type: Boolean,
      required: false,
    },
    readmoreCharLimiter: {
      type: String,
      default: '',
    },
    readmoreText: {
      type: String,
      default: '',
    },
    readlessText: {
      type: String,
      default: '',
    },
    videoControlsPosition: {
      type: String,
      default: 'bottom-left',
    },
    isRedesign: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      proxy: { ...this.$props },
      buttonText: '',
      expanded: false,
      charLimit: this.readmoreCharLimiter,
      buttonEnabled: false,
    };
  },
  computed: {
    sources() {
      return this.proxy?.video?.sources ?? {};
    },
    ...mapGetters('filterList', ['getFilterDataset']),
  },
  created() {
    eventBus.on(HERO_VIDEO_UPDATE, () => {
      this.proxy = this.getFilterDataset.banner ? this.getFilterDataset.banner : this.proxy;
    });
  },
  mounted() {
    if (this.isRedesign) {
      this.getLineCount();
    }
  },
  methods: {
    showText() {
      this.expanded = !this.expanded;
    },
    getTruncatedText(text) {
      if (
        // eslint-disable-next-line sonarjs/no-redundant-boolean
        (this.isReadmoreEnabled == 'true' || this.isReadmoreEnabled == true) &&
        (this.expanded || text.length <= this.charLimit)
      ) {
        return this.textToHTML(text);
      } else if (
        // eslint-disable-next-line sonarjs/no-redundant-boolean
        (this.isReadmoreEnabled == 'true' || this.isReadmoreEnabled == true) &&
        (!this.expanded || text.length >= this.charLimit)
      ) {
        return this.textToHTML(text.slice(0, this.charLimit));
      } else {
        return this.textToHTML(text);
      }
    },
    textToHTML(str) {
      var dom = document.createElement('div');
      dom.innerHTML = str;
      return dom.innerHTML;
    },
    getLineCount() {
      const description = this.$refs.filterDescription.innerText;
      const characterCount = description.length;
      if (characterCount > 93) {
        this.buttonEnabled = true;
      }
    },
  },
};
</script>
