<template>
  <div class="search-wrapper">
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <input
      v-model="search"
      type="text"
      placeholder="Search articles here"
      name="search"
      class="search-wrapper__input"
      @keydown.enter.prevent="filterDataOnSearch()"
      @keydown.space.prevent="filterDataOnSearch()"
    />
    <span
      class="iconSpan"
      @click.prevent="filterDataOnSearch()"
      @keydown.enter.prevent="filterDataOnSearch()"
      @keydown.space.prevent="filterDataOnSearch()"
      ><svg aria-hidden="true" class="icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#search"></use></svg
    ></span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'FilterSearch',

  props: {
    searchDataset: { type: Object, required: true },
  },

  data() {
    return {
      search: '',
    };
  },

  mounted() {
    const location = window.location.search;
    const queryParams = new URLSearchParams(location);
    const searchParam = queryParams.get('q');
    searchParam ? (this.search = searchParam) : (this.search = '');
  },

  methods: {
    ...mapActions('filterList', ['getNewItems']),

    filterDataOnSearch() {
      this.$parent.setInitialItems({ clearAllFilter: true });
      let apiUrl;
      if (this.search) {
        apiUrl = this.searchDataset.searchTermApiUrl.concat('&q=').concat(this.search);
        let pushState = false;
        this.getNewItems(apiUrl);
        let path = window.location.href.split('?')[0];
        path = path.concat('?q=').concat(this.search);
        this.pushUrlToBrowser(pushState || path);
      }
    },

    pushUrlToBrowser(url) {
      window.history.pushState({}, document.title, url);
    },
  },
};
</script>
