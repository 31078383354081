<template>
  <div
    :class="[
      { 'filterGrid -articlesGrid': gridDataset.gridType === 'ArticlesList' },
      { 'filterGrid -ingredientsGrid': gridDataset.gridType === 'IngredientsList' },
      {
        'oap-card-list oap-card-list--layout-grid':
          gridDataset.gridType === 'ProductsList' ||
          gridDataset.gridType === 'ProductsListTwoColumns',
      },
    ]"
  >
    <template
      v-if="
        gridDataset.gridType === 'ProductsList' || gridDataset.gridType === 'ProductsListTwoColumns'
      "
    >
      <!--OAP Promo Cards-->
      <!--#1 card-->
      <OapPromoCard v-if="hasPromoCards" :item="promoCardsList[0]" position="1"></OapPromoCard>
      <OapPromoCard v-if="hasPromoCards" :item="promoCardsList[1]" position="6"></OapPromoCard>
      <OapPromoCard v-if="hasPromoCards" :item="promoCardsList[2]" position="9"></OapPromoCard>
      <!--OAP Promo Cards-->
      <OapCard
        v-for="(item, index) in itemsCollection"
        :key="`productList_` + index + `_` + item.id"
        :display-review-count="displayReviewCount"
        :item="item"
        :layouts="productGridLayouts"
        :productsfull-search-result="gridProductPage"
        :position-on-grid="getPositionOnGridWithPromoCards(index)"
        :promo-card-items="promoCardsList"
      >
      </OapCard>
    </template>
    <template v-if="gridDataset.gridType === 'ArticlesList'">
      <div class="filterGrid__itemsWrapper" data-tag-wrapper>
        <article v-for="(item, index) in gridDataset.list" :key="index" class="filterGrid__item">
          <div class="filterGrid__contentWrapper">
            <div v-imageloaded class="filterGrid__imageWrapper image__loading">
              <img
                class="lazyload"
                :sizes="item.itemResult.image.sizes"
                :alt="item.itemResult.image.alt"
                :aria-hidden="true"
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                :srcset="item.itemResult.image.dataSrcset"
              />
            </div>
            <div class="Grid__contentWrapper">
              <p
                v-if="item.itemResult.label && !gridDataset.isbrandActivated"
                class="filterGrid__brand"
              >
                {{ item.itemResult.label }}
              </p>
              <p role="heading" aria-level="2">
                <a
                  v-tag:useractionevent="{
                    category: 'content page',
                    action: 'select::article',
                    label: item.itemResult.title,
                    module_name: 'article list',
                    cta_name: item.itemResult.label,
                    link_url: `${urlOrigin}${item.url}`,
                    article_name: item.itemResult.title,
                    event_name: 'body_button_click',
                  }"
                  class="filterGrid__title filterGrid__tileLink"
                  :href="item.url"
                  :aria-label="generateAriaLabel(item.itemResult.title, item.itemResult.label)"
                >
                  {{ item.itemResult.title }}
                </a>
              </p>
              <p v-if="item.itemResult.description" class="filterGrid__description">
                {{ item.itemResult.description }}
              </p>
              <div v-if="item.itemResult.ctaDescription" class="filterGrid__action">
                <a
                  v-tag:useractionevent="{
                    category: 'content page',
                    action: 'select::article',
                    label: item.itemResult.title,
                    module_name: 'article list',
                    cta_name: item.itemResult.label,
                    link_url: `${urlOrigin}${item.url}`,
                    article_name: item.itemResult.title,
                    event_name: 'body_button_click',
                  }"
                  class="oap-button"
                  :href="item.url"
                  :aria-label="generateAriaLabel(item.itemResult.title, item.itemResult.label)"
                >
                  {{ item.itemResult.ctaDescription }}
                </a>
              </div>
              <template>
                <div class="likecontainer">
                  <button v-if="!likeHidden" :id="item.id" class="like__btn animated">
                    <span
                      class="like-button"
                      @click="
                        likecounters(item.likeCount, item.id);
                        likecache(item.id);
                        getTaggingInfo(item.itemResult.title, item.likeCount, item.id);
                      "
                    ></span>
                    <span v-if="item.likeCount == 0" class="like__number"></span>
                    <span v-else class="like__number">{{ item.likeCount }}</span>
                  </button>
                </div>
              </template>
            </div>
          </div>
        </article>
      </div>
    </template>

    <template v-if="gridDataset.gridType === 'IngredientsList'">
      <div class="filterGrid__itemsWrapper" data-tag-wrapper>
        <article v-for="(item, index) in gridDataset.list" :key="index" class="filterGrid__item">
          <div class="ingredientTile">
            <div class="ingredientTile__wrapper">
              <div class="ingredientTile__imageWrapper">
                <img
                  class="lazyload"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  :data-src="item.itemResult.image.fileName"
                  :alt="item.itemResult.image.alt"
                  :aria-hidden="true"
                />
              </div>
              <div class="ingredientTile__infoWrapper">
                <p class="ingredientTile__title" role="heading" aria-level="2">
                  <a
                    v-tag:useractionevent="{
                      category: 'product ingredients',
                      action: 'select::ingredient',
                      label: item.itemResult.title,
                    }"
                    :href="item.url"
                  >
                    {{ item.itemResult.title }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </template>
  </div>
</template>

<script>
import OapCard from './../../../../OapCard/code/Scripts/components/OapCard.vue';
import OapPromoCard from './../../../../OapPromoCard/code/Scripts/components/OapPromoCard.vue';
import { mapState } from 'vuex';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
import eventBus from '@loreal/eventbus-js';
import { imageloaded } from '../../../../../Foundation/Core/code/Scripts/directives/imageloaded/imageloaded';
import { tag } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';
import { productItemToProductCardMapper } from '../../../../../Foundation/Core/code/Scripts/mappers/productItemToProductCardMapper';

export default {
  name: 'Grid',

  components: {
    OapCard,
    OapPromoCard,
  },

  directives: {
    imageloaded,
    tag,
  },

  props: {
    displayReviewCount: {
      default: false,
      type: Boolean,
    },
    gridDataset: { type: Object, required: true },
    gridProductPage: {
      default: false,
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      itemsArticle: [],
      allitemsArticle: [],
      ratings: 5,
      incrementer: 1
    };
  },

  computed: {
    ...mapState('filterList', ['loading', 'lazyImages', 'likeHidden']),

    // todo: Next 2 computed are temporary workaround until BE can process data at controller level
    productGridLayouts() {
      const layoutsArray = ['in-grid'];

      if (this.gridDataset.list.some((item) => item.itemResult.colors))
        layoutsArray.push('with-shades');

      return layoutsArray;
    },

    itemsCollection() {
      const mapper = (item) => productItemToProductCardMapper(item, this.gridDataset);

      return this.gridDataset.gridType === 'ProductsList' ||
        this.gridDataset.gridType === 'ProductsListTwoColumns'
        ? this.gridDataset.list.map(mapper)
        : null;
    },

    promoCardsList() {
      return this.gridDataset.promoCardList;
    },

    hasPromoCards() {
      return this.promoCardsList && this.promoCardsList.length != 0 ? true : false;
    },

    urlOrigin() {
      return window.location.origin;
    },
  },

  mounted() {
    /* istanbul ignore next */
    eventBus.on('focus::gridElement', (elementCount) => {
      let interval = false;
      interval = setInterval(() => {
        if (!this.loading) {
          document.querySelectorAll('.filterGrid__tileLink')[elementCount].focus();
          clearInterval(interval);
        }
      }, 200);
    });

    var articlelikeval = document.getElementById('articlelikesetting')?.value;

    if (articlelikeval == 'True') {
      var hideableSections = document.getElementById('filterList');
      hideableSections.classList.add('active_likecontainer');

      var allAricles = document.querySelectorAll('.like__btn');

      for (var i = 0; i < allAricles.length; i++) {
        let allarticleIds = allAricles[i].getAttribute('id');
        this.allitemsArticle.push(allarticleIds);
      }

      var articlelike = JSON.parse(localStorage.getItem('articlelike'));
      var numberOfChildren = articlelike && articlelike.length;
      if (numberOfChildren > 0) {
        for (let i = 0; i < articlelike.length; i++) {
          for (var j = 0; j < allAricles.length; j++) {
            let allarticleIds = allAricles[j].getAttribute('id');
            var currentItem = articlelike[i];
            if (allarticleIds == currentItem) {
              document.getElementById(articlelike[i]).classList.add('active_like');
            }
          }
          this.itemsArticle.push(articlelike[i]);
        }
      }

      fetch(`/api/sitecore/article/GetLikeCountList?&idList=${this.allitemsArticle}`)
        .then((response) => response.json())
        .then((jsonData) => {
          var hideableSections = document.querySelectorAll('.like__btn');
          for (var i = 0; i < hideableSections.length; i++) {
            var allarticleIds = hideableSections[i].getAttribute('id');
            if (jsonData.list[i].likeCount <= 0) {
              document
                .getElementById(allarticleIds)
                .getElementsByClassName('like__number')[0].innerHTML = '';
            } else {
              document
                .getElementById(allarticleIds)
                .getElementsByClassName('like__number')[0].innerHTML = jsonData.list[i].likeCount;
            }
          }
        })
        .catch((e) => console.error(e));
    }
  },
  methods: {
    getPositionOnGridWithPromoCards(index) {
      const indexer = Number.parseInt(index);
      if (index === 0) return 2;
      if (index < 5) return indexer + 2;
      if (index >= 5) return indexer + 3;

    },
    generateAriaLabel(title, label) {
      let generateAriaLabel;
      if (title || label) {
        generateAriaLabel = `${title || ''} ${label ? `(${label})` : ''}`;
      } else {
        generateAriaLabel = false;
      }
      return generateAriaLabel;
    },

    likecounters: function (likeCount, itemid) {
      var activeId = document.getElementById(itemid);

      if (activeId.classList.contains('active_like')) {
        likeCount = activeId.getElementsByClassName('like__number')[0].innerHTML;
        likeCount--;
        activeId.classList.remove('active_like');
        activeId.getElementsByClassName('like__number')[0].innerHTML = likeCount;
        fetch(`/api/sitecore/article/UpdateLikeCount?operatorValue=-1&itemID=${itemid}`)
          .then((response) => response.json())
          .catch((e) => console.error(e));
      } else {
        likeCount = activeId.getElementsByClassName('like__number')[0].innerHTML;
        likeCount++;
        activeId.classList.add('active_like');
        activeId.getElementsByClassName('like__number')[0].innerHTML = likeCount;
        fetch(`/api/sitecore/article/UpdateLikeCount?operatorValue=1&itemID=${itemid}`)
          .then((response) => response.json())
          .catch((e) => console.error(e));
      }
    },

    likecache(itemid) {
      var activeId = document.getElementById(itemid);

      if (activeId.classList.contains('active_like')) {
        this.itemsArticle.push(itemid);
      } else {
        const index = this.itemsArticle.indexOf(itemid);
        if (index > -1) {
          this.itemsArticle.splice(index, 1);
        }
      }
      localStorage.setItem('articlelike', JSON.stringify(this.itemsArticle));
    },

    getTaggingInfo(articletitle, likeCount, itemid) {
      var activeId = document.getElementById(itemid);
      likeCount = activeId.getElementsByClassName('like__number')[0].innerHTML;

      if (activeId.classList.contains('active_like')) {
        likeCount++;
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          ecommerce: 'undefined',
          category: 'content page',
          action: 'like::article',
          label: articletitle + likeCount,
        });
      } else {
        likeCount--;
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          ecommerce: 'undefined',
          category: 'content page',
          action: 'unlike::article',
          label: articletitle + likeCount,
        });
      }
    },
  },
};
</script>
