<template>
  <details class="filter-facet">
    <summary class="filter-facet__header">
      <div class="filter-facet__title">
        <h3 class="filter-facet__title-text">{{ title }}</h3>
        <div class="filter-facet__arrow"></div>
      </div>
      <div class="filter-facet__selected-tags">
        <span v-for="(tag, t) in checkedItems" :key="t"
          >{{ tag }}<template v-if="t !== checkedItems.length - 1">, </template></span
        >
      </div>
    </summary>
    <ul class="filter-facet__list">
      <!-- TODO: move the following element into a standalone component (generic form design update - generic-options.vue) -->
      <li v-for="(facet, f) in facets" :key="f" class="filter-facet__list-item">
        <label :for="facet.id" :class="{ '-disabled': facet.disabled || isLoading }">
          <input
            :id="facet.id"
            v-model="checkedItems"
            v-tag:useractionevent.dom="{
              category: category,
              action: 'filter::by needs',
              label: `${title}::${facet.title}`,
              filter_type: 'filter',
              filter_value: `${filterValue.length > 0 ? filterValue : facet.title}`,
              event_name: 'use_filters',
            }"
            type="checkbox"
            :disabled="facet.disabled || isLoading"
            :name="facet.title"
            :value="facet.title"
            @change="
              inputChanged(checkedItems.length, {
                apiUrl: facet.urlToApiWithParams,
                url: facet.urlToShow,
              });
              // Used by OAP CN site
              likecountert();
            "
          />
          <span class="checkmark"></span>
          <span
            class="filter-facet-title"
            :class="{ '-selected': checkedItems.includes(facet.title) }"
            >{{ facet.title }}</span
          >
        </label>
      </li>
    </ul>
  </details>
</template>

<script>
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'FilterFacet',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    facets: {
      type: Array,
      default: () => [],
      required: true,
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    selectedBrands: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkedItems: [],
      lastCount: 0,
      difference: 0,
      operation: 'increase',
      category: window.wsf.AnalyticsVariables.pageCategory
        ? window.wsf.AnalyticsVariables.pageCategory
        : 'content page',
      filterValue: '',
    };
  },
  mounted() {
    this.populateSelectedFilters(this.selectedTags, this.selectedBrands);
  },
  methods: {
    inputChanged(count, filteringData) {
      if (count > this.lastCount) {
        this.operation = 'increase';
        this.difference = count - this.lastCount;
      } else {
        this.operation = 'decrease';
        this.difference = this.lastCount - count;
      }
      eventBus.emit('updateCount', {
        operation: this.operation,
        difference: this.difference,
        filteringData,
      });
      this.lastCount = count;
    },
    clearSelects() {
      this.checkedItems.splice(0);
      this.lastCount = 0;
      this.difference = 0;
    },
    async populateSelectedFilters(tags, brands) {
      await this.facets.forEach((facet) => {
        tags.forEach((tag) => {
          if (tag.title === facet.title) this.checkedItems.push(tag.title);
        });
        brands.forEach((brand) => {
          if (brand.title === facet.title) this.checkedItems.push(brand.title);
        });
      });
      this.inputChanged(this.checkedItems.length, null);
    },
    // Used by OAP CN site
    likecountert() {
      setTimeout(() => {
        var activeArticles = document.querySelectorAll('.active_like');
        for (var i = 0; i < activeArticles.length; i++) {
          var allarticleIdsActive = activeArticles[i].getAttribute('id');
          var activeId = document.getElementById(allarticleIdsActive);
          activeId.classList.remove('active_like');
        }

        var allAricles = document.querySelectorAll('.like__btn');
        for (let i = 0; i < allAricles.length; i++) {
          var allarticleIdsAll = allAricles[i].getAttribute('id');
          this.allitemsArticle.push(allarticleIdsAll);
        }

        var articlelike = JSON.parse(localStorage.getItem('articlelike'));
        var numberOfChildren = articlelike && articlelike.length;
        if (numberOfChildren > 0) {
          for (let i = 0; i < articlelike.length; i++) {
            for (var j = 0; j < allAricles.length; j++) {
              var allarticleIds = allAricles[j].getAttribute('id');
              var currentItem = articlelike[i];
              if (allarticleIds == currentItem) {
                document.getElementById(articlelike[i]).classList.add('active_like');
              }
            }
          }
        }

        const selectedFilterTags = document.querySelectorAll('.-selected');
        const filterValues = [];
        selectedFilterTags.forEach((item) => {
          if (item.control) {
            // TODO: check why item.control doesn't exist (see filter-tags.vue line 76 for reference)
            filterValues.push(item.control.name);
          }
        });
        this.filterValue = filterValues.join('::');

        fetch(`/api/sitecore/article/GetLikeCountList?&idList=${this.allitemsArticle}`)
          .then((response) => response.json())
          .then((jsonData) => {
            var hideableSections = document.querySelectorAll('.like__btn');
            for (var i = 0; i < hideableSections.length; i++) {
              var allarticleIds = hideableSections[i].getAttribute('id');
              if (jsonData.list[i].likeCount <= 0) {
                document
                  .getElementById(allarticleIds)
                  .getElementsByClassName('like__number')[0].innerHTML = '';
              } else {
                document
                  .getElementById(allarticleIds)
                  .getElementsByClassName('like__number')[0].innerHTML = jsonData.list[i].likeCount;
              }
            }
          })
          .catch((e) => console.error(e));
      }, 2599);
    },
  },
};
</script>
