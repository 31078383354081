<template>
  <div class="filterList__colorWheelGroup">
    <fieldset class="filterList__colorWheelWrapper">
      <legend>{{ filter.title }}</legend>

      <div v-selectbox class="selectbox -dark">
        <div ref="selectBoxWrapper" class="selectbox-wrapper">
          <select
            id="select-color-wheel"
            name="select"
            @change="filterProductsOnChange($event.target)"
          >
            <option
              :name="filter.title"
              :value="colorDropdownPlaceholder"
              selected="true"
              class="-off"
              disabled="disabled"
            >
              {{ colorDropdownPlaceholder }}
            </option>

            <option
              v-for="facet in facetsEnabled"
              :key="facet.id"
              name="color"
              :value="facet.title"
              :selected="facet.selected"
              :data-url-to-api-with-params="facet.urlToApiWithParams"
              :data-url-to-show="facet.urlToShow"
            >
              {{ facet.title }} ({{ facet.count }})
            </option>
          </select>
          <div class="selectbox-labelWrapper">
            <label for="select-color-wheel">{{ colorDropdownPlaceholder }}</label>
            <span v-if="selectedColor" class="selected-value">{{ selectedColor.title }}</span>
          </div>
          <span class="selectbox-indicator"></span>
        </div>
      </div>

      <div class="color-wheelWrapper" aria-hidden="true" :style="{ width: `${size}px` }">
        <div id="color-wheel" ref="container"></div>
        <div
          v-if="onlyOneColor"
          class="color-wheelOneSelected"
          :style="{
            width: `${size / 1.1}px`,
            height: `${size / 1.1}px`,
            background: selectedColor.extraParams.hexColor,
          }"
        ></div>
        <div
          class="color-wheelCircle"
          :style="{
            width: `${size / 1.8}px`,
            height: `${size / 1.8}px`,
            lineHeight: `${size / 1.8}px`,
            background: selectedColor ? selectedColor.extraParams.hexColor : '#000',
          }"
        >
          <button
            v-if="selectedColor"
            class="color-wheelClose"
            @click.prevent="
              filterProducts(selectedColor.urlToApiWithParams, selectedColor.urlToShow)
            "
          >
            <svg role="img" aria-labelledby="clear-color-icon" class="icon is-small">
              <title id="clear-color-icon">Clear</title>
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
            </svg>
          </button>
        </div>
        <div
          v-show="hoverColor"
          class="color-wheelCircleHover"
          :style="{
            width: `${size / 1.8}px`,
            height: `${size / 1.8}px`,
            lineHeight: `${size / 1.8}px`,
          }"
        >
          {{ hoverColor.title }} <span class="facet-count">({{ hoverColor.count }})</span>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import SVG from 'svg.js';
import selectbox from '../../../../../Foundation/Core/code/Scripts/directives/selectbox/selectbox';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'FilterColorWheel',

  directives: {
    selectbox,
  },

  props: {
    filter: { type: Object, required: true },
    size: { type: Number, required: false, default: 300 },
    colorDropdownPlaceholder: { type: String, required: false },
  },
  data() {
    return {
      sectorData: null,
      sectorsToShow: [],
      wheel: null,
      sectors: [],
      selectedColor: false,
      hoverColor: false,
      onlyOneColor: false,
      isIeOrEdge: false,
      hasTouch: false,
      category: window.wsf.AnalyticsVariables.pageCategory || 'undefined',
      analyticsActionString: 'filter::by needs',
    };
  },

  computed: {
    facetsEnabled() {
      return this.filter.facets.filter((f) => !f.disabled);
    },
  },

  watch: {
    filter(newfilters, oldfilters) {
      /* istanbul ignore next */
      this.unInitWheel();
      this.sectorData = newfilters.facets;
      var oldfiltersData = oldfilters.facets;
      /* istanbul ignore next */
      if (this.sectorData.length > 0 && oldfiltersData.length > 0) {
        var changed = this.sectorData.filter(function (p, idx) {
          return p['selected'] !== oldfiltersData[idx]['selected'];
        });
        if (changed && changed.length > 0 && changed[0].selected) {
          this.selectedColor = changed[0] || false;
        } else {
          this.selectedColor = this.sectorData.find((facet) => facet.selected === true) || false;
        }
      } else {
        this.selectedColor = this.sectorData.find((facet) => facet.selected === true) || false;
      }

      /* istanbul ignore next */
      this.onlyOneColor = this.sectorData.length === 1;

      /* istanbul ignore next */
      this.initWheel();
    },
  },
  mounted() {
    this.sectorData = this.filter.facets;
    this.selectedColor = this.sectorData.find((facet) => facet.selected === true) || false;
    this.onlyOneColor = this.sectorData.length === 1;
    this.isIeOrEdge = document.documentMode || /Edge/.test(navigator.userAgent);
    this.hasTouch = navigator.maxTouchPoints || 'ontouchstart' in document.documentElement;

    this.initWheel();
  },

  methods: {
    isNotDisabledOrSelected(e) {
      const state = e.target.getAttribute('data-state');

      return !(state.indexOf('-selected') !== -1 || state.indexOf('-disabled') !== -1);
    },

    setSectorsToShow() {
      this.sectorData.forEach((sector) => {
        this.sectorsToShow.push(sector);
      });

      this.sectorsToShow.map((sector) => {
        sector.percentage = 100 / this.sectorsToShow.length;
        return sector;
      });
    },

    calculateSectors() {
      const width = (this.size - 30) / 2;
      let angle = 0;
      let angleRad = 0;
      let sizeZ = 0;
      let sideX = 0;
      let sideY = 0;
      let coordX = 0;
      let coordY = 0;
      let Rotation = 0;
      let angleCalc = 0;
      let arcSweep = 0;
      this.sectors = [];

      this.sectorsToShow.forEach((item) => {
        angle = 360 * (item.percentage / 100);
        angleCalc = angle > 180 ? 360 - angle : angle;
        angleRad = (angleCalc * Math.PI) / 180;
        sizeZ = Math.sqrt(2 * width * width - 2 * width * width * Math.cos(angleRad));

        /* istanbul ignore else */
        if (angleCalc <= 90) {
          sideX = width * Math.sin(angleRad);
        } else {
          sideX = width * Math.sin(((180 - angleCalc) * Math.PI) / 180);
        }

        sideY = Math.sqrt(sizeZ * sizeZ - sideX * sideX);
        coordY = sideY;

        /* istanbul ignore else */
        if (angle <= 180) {
          coordX = width + sideX;
          arcSweep = 0;
        } else {
          coordX = width - sideX;
          arcSweep = 1;
        }

        this.sectors.push({
          percentage: item.percentage,
          arcSweep,
          width,
          X: coordX,
          Y: coordY,
          Rotation,
        });

        Rotation += angle;
      });
    },

    bringSegmentToTop(segment) {
      const parent = segment.parentNode;
      parent.appendChild(segment);
    },

    /* istanbul ignore next */
    drawSectors() {
      /* istanbul ignore next */
      this.wheelWrap = this.wheel.group().move(10, 10);

      /* istanbul ignore next */
      this.sectors.forEach((sector, index) => {
        const path = this.wheelWrap
          .path(
            `M${sector.width}, ${sector.width} L${sector.width}, 0 A${sector.width}, ${sector.width} 0 ${sector.arcSweep}, 1 ${sector.X}, ${sector.Y} z`
          )
          .fill(this.sectorData[index].extraParams.hexColor)
          .attr('transform', `rotate(${sector.Rotation}, ${sector.width}, ${sector.width})`)
          .attr('tabindex', '0')
          .attr('id', this.sectorData[index].id)
          .attr(
            'data-state',
            `${this.sectorData[index].selected ? '-selected' : ''} ${
              this.sectorData[index].disabled ? '-disabled' : ''
            }`
          )
          .mouseover((e) => {
            if (this.isIeOrEdge || this.hasTouch) {
              return;
            }

            if (this.isNotDisabledOrSelected(e)) {
              e.target.setAttribute('style', 'cursor:pointer;');
              this.bringSegmentToTop(path.node);

              if (this.sectors.length >= 3) {
                path.animate(100, '>', 0).scale(1.1);
              } else {
                path.animate(100, '>', 0).scale(1.05);
              }
              this.hoverColor = this.sectorData[index];
            }
          })
          .mouseout((e) => {
            if (this.isIeOrEdge || this.hasTouch) {
              return;
            }

            if (this.isNotDisabledOrSelected(e)) {
              e.target.removeAttribute('style');
              path.animate(100, '>', 0).scale(1);
              this.hoverColor = false;
            }
          })
          .mousedown((e) => {
            if (this.isNotDisabledOrSelected(e)) {
              this.filterProducts(
                this.sectorData[index].urlToApiWithParams,
                this.sectorData[index].urlToShow
              );
              AnalyticsHandler.getAnalyticsHandler().push({
                type: 'userActionEvent',
                category: this.category,
                action: this.analyticsActionString,
                label: `${this.filter.title.toLowerCase()}::${this.sectorData[
                  index
                ].title.toLowerCase()}`,
              });
            }
          })
          .on('keypress', (e) => {
            const keyCode = e.which || e.keyCode || 0;

            if (this.isNotDisabledOrSelected(e) && (keyCode === 13 || keyCode === 32)) {
              this.filterProducts(
                this.sectorData[index].urlToApiWithParams,
                this.sectorData[index].urlToShow
              );
              AnalyticsHandler.getAnalyticsHandler().push({
                type: 'userActionEvent',
                category: this.category,
                action: this.analyticsActionString,
                label: `${this.filter.title.toLowerCase()}::${this.sectorData[
                  index
                ].title.toLowerCase()}`,
              });
            }
          });
        this.sectorData[index].path = path;
      });
    },

    bindEvents() {
      const paths = this.$refs.container.querySelectorAll('.color-wheelColor');
      Array.prototype.forEach.call(paths, (path) => {
        path.addEventListener(
          'focus',
          (e) => {
            if (this.isNotDisabledOrSelected(e)) {
              this.hoverColor = this.sectorData.find((facet) => facet.id === e.target.id);
              e.target.style.opacity = 0.9;
            }
          },
          false
        );

        path.addEventListener(
          'blur',
          (e) => {
            this.hoverColor = false;
            e.target.style.opacity = 1;
          },
          false
        );
      });
    },

    unbindEvents() {
      const paths = this.$refs.container.querySelectorAll('.color-wheelColor');
      Array.prototype.forEach.call(paths, (path) => {
        path.removeEventListener('focus');
        path.removeEventListener('blur');
      });
    },

    /* istanbul ignore next */
    unInitWheel() {
      this.wheel.clear();
      this.wheel.remove();
      this.wheel = null;

      this.sectorData = null;
      this.sectorsToShow = [];
      this.sectors = [];
      this.selectedColor = false;
      this.hoverColor = false;

      this.unbindEvents();
    },

    /* istanbul ignore next */
    initWheel() {
      this.wheel = SVG(this.$refs.container).size(this.size, this.size);
      this.setSectorsToShow();
      this.calculateSectors();
      this.drawSectors();
      this.bindEvents();
    },

    filterProductsOnChange(eventTarget) {
      const colorTitle = eventTarget.querySelector('option:checked').value;

      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        category: this.category,
        action: this.analyticsActionString,
        label: `${this.filter.title.toLowerCase()}::${colorTitle.toLowerCase()}`,
      });

      this.$parent.filterProductsOnChange(eventTarget);
    },

    filterProducts(apiUrl, pushState = false) {
      this.$parent.filterProducts(apiUrl, pushState);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-wheelOneSelected,
.color-wheelCircle,
.color-wheelCircleHover {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #000;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 50%);
  color: #fff;
  text-align: center;
}

path:focus {
  outline: none;
}
</style>
