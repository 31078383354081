import filterListStore from './code/Scripts/store';
import FilterList from './code/Scripts/components/filter-list.vue';
import FilterAccordion from './code/Scripts/components/filter-accordion.vue';
import filterShortcutLinks from './code/Scripts/components/filter-shortcut-links.vue';
import Facets from './code/Scripts/components/facets.vue';
import FilterFacet from './code/Scripts/components/filter-facet.vue';
import FilterSorting from './code/Scripts/components/filter-sorting.vue';
import FilterTags from './code/Scripts/components/filter-tags.vue';
import FilterDropdown from './code/Scripts/components/filter-dropdown.vue';
import FilterColorWheel from './code/Scripts/components/filter-color-wheel.vue';
import FilterHeaderContainer from './code/Scripts/components/filter-header-container.vue';
import Grid from './code/Scripts/components/grid.vue';
import SelectedFilters from './code/Scripts/components/selected-filters.vue';
import LoadMore from './code/Scripts/components/load-more.vue';
import FilterSearch from './code/Scripts/components/filter-search.vue';

export default {
  components: {
    FilterList,
    FilterAccordion,
    filterShortcutLinks,
    Facets,
    FilterFacet,
    FilterSorting,
    FilterTags,
    FilterDropdown,
    FilterColorWheel,
    FilterHeaderContainer,
    Grid,
    SelectedFilters,
    LoadMore,
    FilterSearch,
  },
  modules: {
    filterList: filterListStore,
  },
};
