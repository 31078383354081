<template>
  <div class="filterList__dropdownGroup">
    <fieldset class="filterList__dropdownWrapper">
      <legend>{{ filter.title }}</legend>
      <div class="selectbox -dark">
        <div class="selectbox-wrapper">
          <select
            id="select"
            ref="select"
            name="select"
            @change="filterProductsOnChange($event.target)"
          >
            <option
              :name="filter.title"
              :value="brandDropdownPlaceholder"
              class="-off"
              selected="true"
              disabled="disabled"
            >
              {{ brandDropdownPlaceholder }}
            </option>
            <option
              v-for="facet in filter.facets"
              v-if="!facet?.disabled"
              :key="facet.id"
              :name="filter.title"
              :value="facet.title"
              :data-url-to-api-with-params="facet.urlToApiWithParams"
              :data-url-to-show="facet.urlToShow"
              @click="blurSelect()"
            >
              {{ facet.title }} ({{ facet.count }})
            </option>
          </select>
          <div class="selectbox-labelWrapper">
            <label for="select">{{ brandDropdownPlaceholder }}</label>
            <span v-show="selectedBrand" class="selected-value">{{ selectedBrand.title }}</span>
          </div>
          <span class="selectbox-indicator"></span>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import selectbox from '../../../../../Foundation/Core/code/Scripts/directives/selectbox/selectbox';

export default {
  name: 'FilterDropdown',
  directives: {
    selectbox,
  },
  props: {
    filter: { type: Object, required: true },
    brandDropdownPlaceholder: { type: String, required: false },
  },
  data() {
    return {
      selectedBrand: false,
    };
  },
  watch: {
    filter() {
      /* istanbul ignore next */
      this.selectedBrand = this.filter.facets.find((facet) => facet.selected === true) || false;
    },
  },
  mounted() {
    this.selectedBrand = this.filter.facets.find((facet) => facet.selected === true) || false;
  },

  methods: {
    blurSelect() {
      /* istanbul ignore else */
      if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        this.$refs.select.blur();
      }
    },

    filterProductsOnChange(eventTarget) {
      /* istanbul ignore next */
      this.$parent.filterProductsOnChange(eventTarget);
    },

    filterProducts(apiUrl, pushState = false) {
      /* istanbul ignore next */
      this.$parent.filterProducts(apiUrl, pushState);
    },
  },
};
</script>
