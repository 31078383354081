<template>
  <div class="filter-accordion">
    <div class="filter-accordion__header">
      <h2 aria-live="polite">
        {{ initialData.dictionary.filterTitle }}
        <template v-if="totalFilters !== 0">({{ totalFilters }})</template>
      </h2>
      <OapLoader v-if="showOverlay && !noLoader" />
      <button class="facets__closeButton" @click="closeFilterProductsDialog">
        <svg class="facets__toggleIcon" role="img" aria-hidden="true">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close" />
        </svg>
      </button>
      <button v-if="totalFilters !== 0" class="facets__clearAll" @click="wipeSelection">
        {{ initialData.dictionary.clearFacets }}
      </button>
    </div>
    <div class="filter-accordion__items">
      <template v-if="tagsData.tagsCollection.length > 0">
        <FilterFacet
          v-for="item in tagsData.tagsCollection"
          ref="filterFacet"
          :key="item.id"
          :title="item.title"
          :facets="item.facets"
          :selected-tags="tagsData.selectedTags"
          :selected-brands="tagsData.selectedBrands"
          :is-loading="showOverlay"
        ></FilterFacet>
      </template>
    </div>
    <div class="filter-accordion__footer">
      <div class="filter-accordion__footer__wrapper">
        <button
          class="oap-button -primary oap-button__border clear-all"
          :class="{ '-disabled': showOverlay }"
        >
          <span class="oap-button__label" @click="wipeSelection">{{
            initialData.dictionary.clearFacets
          }}</span>
          <span>
            <svg role="img" aria-hidden="true" class="clearall__toggleIcon">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
            </svg>
          </span>
        </button>
        <button
          class="oap-button -primary apply"
          :class="{ '-disabled': showOverlay }"
          @click="closeFilterProductsDialog"
        >
          <span class="oap-button__label">{{ initialData.dictionary.apply }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterFacet from './filter-facet';
import OapLoader from '../../../../OapLoader/code/Scripts/components/OapLoader.vue';
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'FilterAccordion',
  components: {
    FilterFacet,
    OapLoader,
  },
  props: {
    initialData: {
      type: Object,
      default: () => {},
    },
    tagsData: {
      type: Object,
      default: () => {},
      required: true,
    },
    showOverlay: {
      type: Boolean,
      default: false,
    },
    noLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalFilters: 0,
    };
  },
  mounted() {
    eventBus.on('updateCount', (payload) => {
      const updateCountData = {
        operation: payload.operation,
        difference: payload.difference,
      };

      this.updateCount(updateCountData);
      if (!payload.filteringData) return;
      eventBus.emit('filterProducts', {
        data: payload.filteringData,
        url: payload.filteringData.url,
      });
    });
  },
  methods: {
    updateCount(payload) {
      if (payload.operation === 'increase') {
        this.totalFilters = this.totalFilters + payload.difference;
      } else {
        this.totalFilters = this.totalFilters - payload.difference;
      }
    },
    wipeSelection() {
      const facets = this.$refs.filterFacet;
      facets.forEach((facet) => facet.clearSelects());
      this.totalFilters = 0;
      eventBus.emit('resetFilterProducts', {
        apiURL: this.initialData.clearFilterApiUrl,
        url: this.initialData.clearFilterUrl,
      });
    },
    closeFilterProductsDialog() {
      eventBus.emit('closeFilterProductsDialog');
    },
  },
};
</script>
