export const productItemToProductCardMapper = function (productItem, context) {
  const item = {
    // html: null,
    // language: 'es-AR',
    // path: '',
    // position: 1,
    // category: 'Pelo',
    // subCategory: 'Cuidado del Pelo',
    // subBrand: 'Colorvive',
    // priceNumber: productItem.itemResult.priceNumber,
    // currencySymbol: this.gridDataset.currencySymbol,
    id: productItem.itemResult.ean,
    type: productItem.itemResult.type.toLowerCase(),
    url: productItem.url,
    buyNowUrl: productItem.buyNowUrl,
    brand: productItem.itemResult.brand,
    title: productItem.itemResult.title,
    price: productItem.itemResult.price,
    beautySeals: productItem.itemResult.beautySeals,
    ctaClasses: productItem.itemResult.ctaClasses,
    image: productItem.itemResult.image,
    action: {
      text: !productItem.itemResult.comingSoon
        ? context.dictionary.viewResult
        : context.dictionary.comingSoon,
      buyNowText: !productItem.itemResult.comingSoon
        ? context.dictionary.buyNowText
        : context.dictionary.comingSoon,
    },
    tag: {
      // shared by all event type
      common: {
        data: {}, // dataFromDom
        product: {
          // productInfo
          brand: 'OAP',
          variant: productItem.itemResult.brand,
          id: productItem.itemResult.ean,
          name: productItem.itemResult.title,
          position: productItem.position,
          category: productItem.itemResult.analyticsData.taggingCategory,
          dimension36: productItem.itemResult.analyticsData.dimension36,
          dimension38: productItem.itemResult.analyticsData.dimension38,
          dimension48: productItem.itemResult.analyticsData.dimension48,
          dimension49: productItem.itemResult.analyticsData.dimension49,
          dimension91: productItem.itemResult.analyticsData.dimension91,
          dimension176: productItem.itemResult.analyticsData.dimension176,
        },
      },
      // per event type
      productclick: {
        data: {
          label:
            `${productItem.itemResult.title}::${productItem.itemResult.analyticsData.dimension48}`.toLowerCase(),
          list: 'list-result-range',
        },
      },

      productimpression: {
        data: {
          label: 'product selector page',
        },
        product: {
          list: 'list-result-range',
        },
      },
    },
  };

  if (productItem.itemResult.colors)
    item.shades = {
      colorLabel: context.dictionary.colorLabel || '[Color]',
      moreShadesLabel: context.dictionary.moreShadesLabel || '[More shades are available]',
      // todo: add limit to the model,
      // also think about responsive amount on large / small cards
      // remove `moreColorsIcon` from the model
      limit: 5,
      colors: productItem.itemResult.colors,
    };

  // TODO: packshotClass : productItem.image.packshotClass,
  item.image.packshotClass = item.image.packshotClass || 'oap-packshot--regular';

  if (productItem.itemResult.isModifaceActivated) item.label = context.dictionary.tryOnLabel;

  if (context.isStarsActivated)
    item.rating = {
      count: productItem.itemResult.reviewCount || 0,
      max: context.starRatingMaxRatingValue,
      score: productItem.itemResult.starNumber || 0,
      srText: context.dictionary.ratingAverageSrLabel,
      bv: true,
    };

  // tag
  item.tag.common.data.currency = context.currencySymbol;
  item.tag.common.product.price =
    productItem.itemResult.priceNumber === '' ? ' ' : productItem.itemResult.priceNumber;

  return item;
};
