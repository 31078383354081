<template>
  <div id="facets" class="facets">
    <div class="facets__overlayTitle">
      <h2 class="facets__productsQty">{{ facetDataset.resultsString }}</h2>
      <button class="facets__closeButton" @click="toggleFacets()">
        <svg class="facets__toggleIcon" role="img" aria-hidden="true">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close" />
        </svg>
      </button>
    </div>
    <div
      :class="{
        'facets__tagsDropdownColumn--small': isColorWheel,
        'facets__tagsDropdownColumn--wide': !isColorWheel,
      }"
    >
      <template v-if="tagsCollection.length > 0">
        <FilterTags
          v-for="filter in tagsCollection"
          :key="filter.id"
          :filter="filter"
          @on-tag-change="onTagChange"
        ></FilterTags>
      </template>

      <template v-if="dropdownCollection.length > 0">
        <FilterDropdown
          v-for="filter in dropdownCollection"
          :key="filter.id"
          :filter="filter"
          :brand-dropdown-placeholder="facetDataset.dictionary.brandDropdownPlaceholder"
        ></FilterDropdown>
      </template>
    </div>

    <div
      v-if="isColorWheel"
      :class="{
        facets__colorWheelColumn: isColorWheel,
      }"
    >
      <template v-if="colorCollection">
        <FilterColorWheel
          :key="colorCollection.id"
          :filter="colorCollection"
          :color-dropdown-placeholder="facetDataset.dictionary.colorDropdownPlaceholder"
        ></FilterColorWheel>
      </template>
    </div>

    <div class="facets__clearDoneButtonWrapper">
      <button
        v-if="selectedTags.length > 0 || selectedColors.length > 0 || selectedBrands.length > 0"
        class="facets__clearButton button"
        @click="clearAllButtonClick"
      >
        {{ facetDataset.dictionary.clearFacets }}
        <svg class="selectedFilters__closeIcon" :aria-hidden="true">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close" />
        </svg>
      </button>

      <button
        class="facets__doneButton button"
        :disabled="
          decountrycode && !selectedTags.length && !selectedColors.length && !selectedBrands.length
        "
        @click="doneButtonClick"
      >
        {{ facetDataset.dictionary.done }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FilterTags from './filter-tags.vue';
import FilterDropdown from './filter-dropdown.vue';
import FilterColorWheel from './filter-color-wheel.vue';
import { tag } from '@Foundation/analyticsHandler/directives/tag';
import eventBus from '@loreal/eventbus-js';
import { HERO_VIDEO_UPDATE } from '../constants/eventNames';

import { AnalyticsHandler } from '@Foundation';

export default {
  name: 'Facets',

  components: {
    FilterTags,
    FilterDropdown,
    FilterColorWheel,
  },

  directives: {
    tag,
  },

  props: {
    facetDataset: { type: Object, required: false },
  },
  data() {
    return {
      allitemsArticle: [],
      taggingOnDoneObject: {},
    };
  },

  computed: {
    ...mapGetters('filterList', [
      'isColorWheel',
      'colorCollection',
      'dropdownCollection',
      'selectedTags',
      'selectedColors',
      'selectedBrands',
      'tagsCollection',
    ]),
    ...mapState('filterList', {
      loading: (state) => state.loading,
      decountrycode: (state) => state.deCountrycode,
    }),
  },

  mounted() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  },

  methods: {
    ...mapActions('filterList', ['toggleFacets']),

    onTagChange({ tagging }) {
      this.taggingOnDoneObject = tagging;
    },

    focusOnToggler(delay = 100) {
      const toggleFacetsButton = document.getElementById('toggle-facets');

      setTimeout(() => {
        toggleFacetsButton.focus();
      }, delay);
    },

    clearButtonClick() {
      this.filterProducts(
        this.facetDataset.clearFilterApiUrl,
        this.facetDataset.clearFilterUrl
      ).then(() => eventBus.emit(HERO_VIDEO_UPDATE));

      let interval = false;
      interval = setInterval(() => {
        /* istanbul ignore next */
        if (!this.loading) {
          const dropdownOptions = this.$el.querySelectorAll('.filterList__dropdownWrapper option');

          Array.prototype.forEach.call(dropdownOptions, (option) => {
            const optionClassList = [].slice.call(option.classList);

            if (optionClassList.indexOf('-off') === -1) {
              option.selected = false;
            }
          });

          clearInterval(interval);

          document.querySelector('#select').selectedIndex = 0;
        }
      }, 200);

      this.focusOnToggler(500);
    },

    doneButtonClick() {
      this.focusOnToggler();
      this.toggleFacets();

      AnalyticsHandler.getAnalyticsHandler().push({
        ...this.taggingOnDoneObject,
        type: 'userActionEvent',
      });

      eventBus.emit(HERO_VIDEO_UPDATE);
    },

    clearAllButtonClick() {
      this.clearButtonClick();
      this.likecountert();
    },

    filterProductsOnChange(eventTarget) {
      const sortApiUrl = eventTarget.querySelector('option:checked').dataset.urlToApiWithParams;
      const urlToShow = eventTarget.querySelector('option:checked').dataset.urlToShow; // eslint-disable-line

      /* istanbul ignore else */
      if (sortApiUrl && urlToShow) {
        this.filterProducts(sortApiUrl, urlToShow);
      }
    },

    filterProducts(apiUrl, pushState = false) {
      this.$parent.setInitialItems({ clearAllFilter: true });
      return this.$parent.getData(apiUrl, pushState);
    },
    likecountert() {
      setTimeout(() => {
        const activeArticles = document.querySelectorAll('.active_like');
        for (let i = 0; i < activeArticles.length; i++) {
          let allarticleIdsActive = activeArticles[i].getAttribute('id');
          let activeId = document.getElementById(allarticleIdsActive);
          activeId.classList.remove('active_like');
        }

        const allAricles = document.querySelectorAll('.like__btn');
        for (let i = 0; i < allAricles.length; i++) {
          let allarticleIdsAll = allAricles[i].getAttribute('id');
          this.allitemsArticle.push(allarticleIdsAll);
        }

        const articlelike = JSON.parse(localStorage.getItem('articlelike'));
        const numberOfChildren = articlelike && articlelike.length;
        if (numberOfChildren > 0) {
          for (let i = 0; i < articlelike.length; i++) {
            for (let j = 0; j < allAricles.length; j++) {
              let allarticleIds = allAricles[j].getAttribute('id');
              let currentItem = articlelike[i];
              if (allarticleIds == currentItem) {
                document.getElementById(articlelike[i]).classList.add('active_like');
              }
            }
          }
        }

        fetch(`/api/sitecore/article/GetLikeCountList?&idList=${this.allitemsArticle}`)
          .then((response) => response.json())
          .then((jsonData) => {
            var hideableSections = document.querySelectorAll('.like__btn');
            for (var i = 0; i < hideableSections.length; i++) {
              var allarticleIds = hideableSections[i].getAttribute('id');
              if (jsonData.list[i].likeCount <= 0) {
                document
                  .getElementById(allarticleIds)
                  .getElementsByClassName('like__number')[0].innerHTML = '';
              } else {
                document
                  .getElementById(allarticleIds)
                  .getElementsByClassName('like__number')[0].innerHTML = jsonData.list[i].likeCount;
              }
            }
          })
          .catch((e) => console.error(e));
      }, 2599);
    },
  },
};
</script>
